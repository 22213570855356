import PropTypes from "prop-types"
import React from "react"
import logo from "../images/barlogo.svg"

const Header = ({ siteTitle }) => (
  <header
    style={{
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
        textAlign: `center`,
      }}
    >
      <img src={logo}
        style={{
          maxWidth: 400,
        }}
        alt="Philadelphia Bar Association Logo" />
      <h1 
        style={{ 
          margin: 0,
          color: `white`,
          textDecoration: `none`
        }}>
          {siteTitle}
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
