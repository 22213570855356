/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import logo from "../images/barlogo.svg"
import Header from "./header"
import "./layout.scss"

const Layout = ({  children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          <main>{children}</main>
          <div className="volunteer2">
          <a className="volunteer_button" href="https://politics.raisethemoney.com/en/campaign-for-qualified-judges">Help Get The Word Out</a>
          </div>
        </div>
        <footer>
        <div className="footer">
          <div>
            <img src={logo} 
            style={{
              maxWidth: 200,
            }}
            alt="Philadelphia Bar Association Logo"/><br/>
            © {new Date().getFullYear()}, <a href="https://philadelphiabar.org">Philadelphia Bar Association</a><br/>
              <a href="https://philadelphiabar.org/page/JudicialCommission">Judicial Commission Information</a><br/><br/>
            <small>Paid for by the Campaign for Qualified Judges</small><br/>
          </div>
          <div className="footerlist">
            <ul>
              <li><a href="http://www.philadelphiabar.org/page/PrivacyPolicy">Privacy Policy</a></li>
              <li><a href="http://www.philadelphiabar.org/page/Confidentiality">Confidentiality Notice</a></li>
              <li><a href="http://www.philadelphiabar.org/page/Disclaimer">Disclaimer</a></li>
              <li><a href="http://www.philadelphiabar.org/page/AboutStaff">Contact Us</a></li>
              <li><a href="mailto:webmaster@philabar.org">Send Us Your Feedback</a></li>
            </ul>
          </div>
        </div>
        </footer>
        <img src="https://secure.adnxs.com/px?id=1473501&seg=26308406&t=2" width="1" height="1" alt="" />
        <img height="1" width="1" alt="" src="https://insight.adsrvr.org/track/pxl/?adv=yjqxjyl&ct=0:5ukm941&fmt=3" />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
